@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// $mainColor: #282727;
$mainColor: #504538;
$mainColorLight: #5767aa;
$secondaryColor: #F7906E;
// $tertiaryColor: #FFE788;
$tertiaryColor: #C1A788;
$textColor: #FFFF94;
$otherColor: #F2FEDC;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}