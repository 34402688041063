@use './styles.scss';

body {
  margin: 0;
  background-color: black;
}

.homeContainer {
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //background-color: aqua;
}


.mainText {
  position: absolute;
  width: 320px;
  height: 200px;
  top: 0;
  right: 0;
  h1 {
    margin-top: 10px;
    padding: 10px 10px;
    font-size: 2.2rem;
    line-height: 30px;
    font-weight: 900;
    font-style: italic;
    color: rgb(7, 62, 109);
  }
  @media (max-width: 976px) {
    position: relative;
  }
}

.mainImage {
  width: 220px; // adjust as needed
  max-width: 500px; // adjust as needed
  float: right;
  margin-left: 10px;
}

.review {
  width: auto;
  text-align: right;
  font-size: 1.3rem;
  line-height: 20px;
  font-weight: 900;
  font-style: italic;
  color: rgb(123, 14, 112);

  .reviewText {
    // styling for the review text
    text-align: right;
  }

  .reviewAuthor {
    // styling for the review author
    text-align: right;
  }
  
}

.media {
  margin-top: 10px;
  align-self: center;
}

.musicInfo {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  .musicText {
    font-weight:700;
    font-size: 1.75rem;
    color: #003d4c;
  }
  .musicSubtext {
    font-weight: 500;
  }
}

.player {
  display: flex;
  // background-color: red;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-bottom: 6rem;
}



// .container {
//   display: flex;
//   justify-content: center;  // Horizontally centers the content
//   align-items: center;      // Vertically centers the content
//   min-height: 100vh;        // Ensures container takes at least the full viewport height

//   .bgImg {
//     max-width: calc(100vw - 200px);  // Adjust 200px to whatever you desire as total horizontal margin
//     margin: 0px; // Adjust the margin value to whatever you desire
//   }
// }

// .content {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

// .container {
//   position: relative;
//   background: url('../Images/bg.jpg') no-repeat center center;
//   width: 980px;
//   height: 1000px;
//   margin-left: auto;
//   margin-right: auto;

//   .dotButton {
//     z-index: 9999;
//     position: absolute;
//     width: 2%;
//     height: 2%;
//     transform: translate(-50%, -50%); 
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     transition: transform 0.3s ease;
  
//     &:hover {
//       transform: translate(-50%, -50%) scale(1.1);
//     }
  
//     border-radius: 50%; 
//     background-color: white; 
//     opacity: 1;
  
//     &:hover {
//       opacity: 1; 
//     }

//     &.active {
//       background-color: rgb(56, 168, 56);  // or whatever active style you desire
//     }
//   }
// }

// .imageWrapper {
//   position: relative;
//   display: inline-block;

//   .bgImg {
//     display: block; // Removes any unwanted space beneath the image
//     max-width: 100%; // Ensures image is contained within its parent
//   }

//   .dotButton {
//     z-index: 9999;
//     position: absolute;
//     width: 2%;
//     height: 2%;
//     transform: translate(-50%, -50%); 
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     transition: transform 0.3s ease;
  
//     &:hover {
//       transform: translate(-50%, -50%) scale(1.1);
//     }
  
//     border-radius: 50%; 
//     background-color: white; 
//     opacity: 1;
  
//     &:hover {
//       opacity: 1; 
//     }

//     &.active {
//       background-color: rgb(56, 168, 56);  // or whatever active style you desire
//     }
//   }
// }
