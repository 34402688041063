@use './styles.scss';

html, body {
  background-color: black;
  margin: 0;
  height: 100%;
}

.container {
	margin: 0 auto;
	padding: 0px;
	width: 980px;
	min-height: 1000px;
	background: rgb(232,232,232) url("../../public/images/bg.jpg");
	background-repeat: no-repeat;
	text-align:left;
	
	position: relative;
	top: 0px;
	left: 0px;

  @media (max-width: 976px) {
    background: none;
    background-color: rgb(232,232,232);
    width: 100%;
  }
}

.body {
	position: relative;
	left: 326px;
	top: 0px;
	width: 654px;
  @media (max-width: 976px) {
    left: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content {
	font-family: Arial;
	font-size: 15px;
	min-height: 800px;
  //background-color: red;
  margin-right: 2%;
  margin-top: 4rem;
}