@use './styles.scss';

.componentContainer {
  position: absolute;
  top: 0;  /* Adjust these values as needed */
  left: 0; /* Adjust these values as needed */
  /* ... other styles */
}

// Header

.header {
  display: flex;
  flex-direction: row;
}

.logo {
  margin-top: 2rem;
  @media (max-width: 976px) {
    width: 60vw;
    margin-right: 100px;
  }
}

.title {
  font-size: 3rem;
  font-weight: 700;
}
.description {
  // font-size: 1.5rem;
  font-weight: 400;
  // line-height: 1.05;
}
.title, .description {
  margin: 0;
  padding: 0;
}

.calendar {
  padding-left: 10rem;
  @media (max-width: 976px) {
    padding: 0 40px 0 40px;
  }
  .calendarHeader {
    margin-bottom: 2rem;
    // background-color: aqua;
  }
  .calendarContainer {
    //padding: 0 2rem;
    .eventContainer {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      .locationContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
      h1 {
        color: #000000;
        font-style: italic;
        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 0.5rem;
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 0rem;
        line-height: 100%;
      }
      h3 {
        font-style: italic;
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 0rem;
      }
      li {
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 0rem;
      }
    }
  }
}

.videos {
  .videosHeader {
    padding-left: 10rem;
    margin-bottom: 2rem;
    @media (max-width: 976px) {
      padding-left: 0;
    }
  }
  .videoContainer {
    padding-left: 5rem;
    @media (max-width: 976px) {
      padding: 20px;
    }
    .title {
      font-size: 2rem;
      font-weight: 700;
    }
    .video {
      margin: 0;
      height: 315px;
      width: 560px;
      @media (max-width: 976px) {
        width: 100%;
      }
    }
    @media (max-width: 976px) {
      padding-left: 0;
    }
  }
}

.contact {
  .contactHeader {
    padding-left: 10rem;
    margin-bottom: 2rem;
    @media (max-width: 976px) {
      padding: 0 40px 0 40px;
    }
  }
  .contactContainer {
    padding-left: 5rem;
    .title {
      font-size: 2rem;
      font-weight: 700;
    }
    .contact {
      margin: 0;
    }
  }
}

.photoDivideText {
  margin-top: 4rem;
  font-weight: 700;
  font-size: 2rem;
  color: #003d4c;
  @media (max-width: 976px) {
    padding: 0 12.5px 0 20px;
  }
}

.photoDivide {
  width: 100%;
  height: 2px;
  background-color: #003d4c;
  margin-bottom: 2rem;
  opacity: 0.5;
  @media (max-width: 976px) {
    padding: 0 12.5px 0 20px;
    margin-left: 8.5;
  }
}

.music {
  overflow: auto; /* To clear the float */
  padding: 0px 40px 0px 40px;
  .title {
    margin: 0;
  }
  .media {
    float: left;
    margin-right: 1rem;
  }
  .text {
    margin: 0;
  }
}

// Contact Page

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px; /* Adjust the gap as needed */
  @media (max-width: 976px) {
    padding: 0 12.5px 0 20px;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  }
}

.photo {
  position: relative;
  overflow: hidden;
  margin-bottom: 16px; /* Adjust the margin as needed */
}

.photo img {
  width: 100%;
  height: auto;
  display: block;
}

// Presskit

.masterDiv {
  margin-left: 80px;
  @media (max-width: 976px) {
    margin-left: 0;
    padding: 0px 40px 0px 40px;
  }
  
  h2 {
    color: #003d4c;
    font-weight: 400;
    font-size: 27px;
    margin-bottom: 0;
    margin-top:16px;
  }
  
  p {
    color: #000000;
    font-weight: 400;
    margin: 0;
    // line-height: 1.25em;
  }
  
  b {
    color: #000000;
    font-weight: 600;
    margin: 0;
    // line-height: 1.25em;
  }

}

.presskitItem {
  color: #003d4c;
  font-style: italic;
  text-decoration: underline;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  column-gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.electronicContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 8px;
  column-gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.gridsContainer {
  display: flex;
  flex-direction: row;
}

.gridListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  .btitle {
    color: #000000;
    font-size: 18px;
    font-weight: 800;
    margin: 0;
    line-height: 1.25em;
  }
}

.venueContainer {
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-left: 0;
  border-radius: 10px;
}

.venueRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.venueIcon {
  margin-right: 8px;
  margin-left: 8px;
}

// Components

.paragraphGap {
  margin-bottom: 12px;
}

// Mobile menu

.mobileMenu {
  display: none;
  @media (max-width: 976px) {
    display: block;
  }
}


// global

h1 {
  color: #003d4c;
  font-weight: normal;
  font-size: 27px;
  margin-bottom: 0;
}

a {
  color: #003d4c;
}

a:hover {
  text-decoration: none;
}

.menuItem {
  color: #1a1d23;
  text-decoration: none;
}

.musicListWrapper {
  @media (max-width: 976px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 12.5px;
  }
}

// Audio Player
.first {
  float: left;
  margin-right: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  background-color: #f8f8fa;
  color: #1a1d23;
  border-radius: 10px;
  max-width: 15vw;
  min-width: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 976px) {
    float: none;
    margin-right: 0rem;
    margin-left: 0rem;
    align-self: center;
  }
}

.second {
  margin-right: 0;
  margin-left: 0;
  max-width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex: 1;
}

.audioPlayerHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  // background-color: turquoise;
}

.column {
  flex-direction: column;
}

.albumCover {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-right: 2rem;
}

.third {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #1a1d23;
  margin-bottom: -5px;
}

.thirdSub {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #858589;
  margin-bottom: -5px;
}

.twentytwo {
  list-style: none;
}

.outerScrollableContainer {
  position: relative;;
}

.scrollableContainer {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  position: relative;
  scrollbar-color: transparent transparent;
  @media (max-width: 976px) {
    height: 200px;
  }
}

.gradientCover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Adjust this as needed */
  background: linear-gradient(to top,#f8f8fa, transparent);
  pointer-events: none; /* Allows click events to pass through to the scrollable content */
}

.fourth {
  display: flex;
  align-items: center;
  padding: 1rem;
  // margin-bottom: 1rem;
  width: 100%;
  justify-content: space-evenly;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #eaeaec;
  color: #1a1d23;
  &:hover {
    background-color: #e6e6e8;
    border-bottom: 1px solid #f8f8fa;
    border-radius: 10px;
    color: #1a1d23;
  }
}

.fourthActive {
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 1rem;
  // margin-bottom: 1rem;
  width: 100%;
  justify-content: space-evenly;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f8f8fa;
  color: #1a1d23;
  background-color: #e6e6e8;
  color: #1a1d23;
}

.fifth {
  // margin-bottom: 0.25rem;
  // background-color: turquoise;
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sixth {
  font-size: 1.25rem;
  color: #858589;
  font-weight: 500;
  // background-color: red;
}

.seventh {
  display: flex;
  align-self: center;
  flex: 1;
  font-size: 14px;
  margin-top: 0.7rem;
  // background-color: rgb(53, 13, 123);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  font-weight: 500;
}

.eighth {
  margin-top: auto;
}

// Audio Progress Bar

.ninth {
  position: absolute;
  height: 1px;
  top: -4px;
  left: 0;
  right: 0;
  margin-left: 1.75rem;
  margin-right: 1.75rem;

}

.tenth {
  position: absolute;
  inset: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  background: transparent;
  appearance: none;
  cursor: pointer;
  background-color: #e1e1e5;
  height: 3px;
  &.before,
  &.after {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #f6e05e; 
  }

  &.after {
    background-color: rgba(#f6e05e, 0.5);
  }
}

// VolumeInput.tsx

.eleventh {
  width: 80px;
  margin: 0;
  height: 2px;
  border-radius: 1rem;
  background-color: #bbbbbb;
  color: #1a1d23;
  appearance: none;
  cursor: pointer;
}

// AudioPlayer.tsx

.twelth {
  border-radius: 20px;
  // background-color: #f8f8fa; // Adjust with your color variable
  // background-color: red;
  color: #1a1d23; // Adjust with your color variable
  padding: 0.75rem; // Adjust according to your needs
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.thirteenth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
//
.fourteenth {
  text-align: center;
  margin-bottom: 0.25rem;
}

.fifteenth {
  color: #1a1d23; 
  font-weight: 500;
}

.sixteenth {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  // background-color: brown;
  color: #858589;
}

.seventeenth {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 1rem; 
}
//
.eighteenth {
  display: flex;
  align-items: center;
  gap: 1rem; 
  justify-self: center;
  // background-color: red;
}

.ninteenth {
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin infinite linear;
}

.twenty {
  display: flex;
  gap: 0.75rem; 
  align-items: center;
  justify-self: flex-end;
}

.twentyone {
  border: 1px solid #f8f8fa;
  border-radius: 9999px; // Equivalent to Tailwind's 'rounded-full'
  display: flex;
  width: 20px;
  height: 40px;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #f8f8fa;
  focus: {
    outline: none;
    ring: {
      width: 2px;
      color: #1a1d23; // Adjust with your color variable
      offset: 2px;
    }
  }
  &:disabled {
    opacity: 0.6;
  }
}