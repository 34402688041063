@use './styles.scss';

.dotButton {
  z-index: 9999;
  position: absolute;
  width: 2%; // This sets the width
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: translate(-50%, -50%);

  .defaultImage {
    display: block;
  }

  .hoverImage {
    display: none;
  }

  &:hover {
    .defaultImage {
      display: none;
    }

    .hoverImage {
      display: block;
    }
  }
  @media (max-width: 976px) {
    display: none;
  }
}